<template>
  <div class="secure-report container template-1">
    <header class="page-header container mx-lg">
      <h1>{{ translations.components['report-parameters'].tcNatlAssociationSummaries }}</h1>
    </header>
    <section class="container bg-block">
      <b-form-group class="form-element">
        <div class="form_label bold">
          <strong>{{ translations.components['report-parameters'].tcAssociation }}</strong>
        </div>
        <b-form-select
          class="form-control g-select w-full"
          :options="associations"
          v-model="selectedAssociation.org_key"
          :plain="true"
          @change="associationChange($event)"
        >
          <template slot="first">
            <option :value="null" disabled>
              {{ translations.components['report-parameters'].tcSelectAnAssociation }}
            </option>
          </template>
        </b-form-select>
      </b-form-group>
      <b-form-group class="form-element">
        <div class="form_label">
          <strong>{{ translations.components['report-parameters'].tcYear }}</strong>
        </div>
        <b-form-select
          class="form-control g-select w-full"
          :options="associationYears"
          :plain="true"
          v-model="selectedAssociation.year"
          @change="associationYearChange($event)"
        >
          <template slot="first">
            <option :value="null" disabled>{{ translations.components['report-parameters'].tcSelectAYear }}</option>
          </template>
        </b-form-select>
      </b-form-group>
      <b-form-group class="form-element">
        <div v-for="(associationDetail, index) in selectedAssociation.urls" :key="`g${index}`">
          <b-link target="_blank" :href="associationDetail.value"
            ><strong>{{ associationDetail.text }}</strong></b-link
          >
        </div>
      </b-form-group>
      <b-form-group class="form-element">
        <b-button class="btn btn-tertiary btn-w-med ml-4" size="sm" id="btnCancelForm" @click="handleCancelClick">{{
          translations.components['report-parameters'].tcCancel
        }}</b-button>
      </b-form-group>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'national-association-summaries',
  mixins: [translationMixin],
  data() {
    return {
      translations: {
        components: {
          'report-parameters': [],
        },
      },
      title: 'National Association Summaries',
      associations: [],
      associationYears: [],
      selectedAssociation: {
        association: null,
        org_key: null,
        year: null,
        urls: [],
      },
    }
  },
  methods: {
    ...mapActions({
      clearAssociationReportUrls: 'secureReport/clearAssociationReportUrls',
      getAssociations: 'secureReport/getAssociations',
      getAssociationReportYears: 'secureReport/getAssociationReportYears',
      getAssociationReportUrls: 'secureReport/getAssociationReportUrls',
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    async page_load() {
      try {
        this.setLoadingStatus(true)
        await this.clearAssociationReportUrls()
        await this.getAssociations()
        await (this.associations = this.associationDetails.map((item) => {
          return { text: item.org_name, value: item.org_key }
        }))
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async associationChange(evt) {
      try {
        this.setLoadingStatus(true)
        await this.clearAssociationReportUrls()
        this.selectedAssociation.year = null
        this.selectedAssociation.urls = []
        await Promise.all([
          await (this.selectedAssociation.association = this.associationDetails.find(
            (x) => x.org_key === evt
          ).lvl_code),
          await this.getAssociationReportYears(this.selectedAssociation.association),
          (this.associationYears = this.associationYearDetails.map((item) => {
            return { text: item, value: item }
          })),
        ])
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async associationYearChange(evt) {
      try {
        this.setLoadingStatus(true)
        await this.clearAssociationReportUrls()
        await this.getAssociationReportUrls({ associationCode: this.selectedAssociation.association, year: evt }).then(
          () => {
            this.selectedAssociation.urls = this.associationReportDetails.map((item) => {
              var objDate = new Date()
              objDate.setDate(1)
              objDate.setMonth(item.Month - 1)
              var month = objDate.toLocaleString(this.prefCulture, { month: 'long' })
              return { text: month + ' ' + item.Year, value: item.URL }
            })
          }
        )
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    handleCancelClick() {
      this.$router.push({ path: `/reports/report-center` })
    },
  },
  async created() {
    await this.getPseudoComponentTranslations('report-parameters').then((results) => {
      const pseudoComponentTranslations = results
      this.$set(this.translations, 'components', pseudoComponentTranslations)
    })
    await this.page_load()
  },
  computed: {
    ...mapGetters({
      associationDetails: 'secureReport/associations',
      associationReportDetails: 'secureReport/associationReportUrls',
      associationYearDetails: 'secureReport/associationReportYears',
      prefCulture: 'user/userPreferredCulture',
    }),
  },
  components: {},
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';

select {
  height: auto !important;
  padding: 10px 20px !important;
  border-color: #636363 !important;
  color: #003946 !important;
  font-weight: 700 !important;
  line-height: 25px !important;
  max-width: 300px;
}

input[type='date'] {
  border: 1px solid #636363;
  padding: 13px 20px 13px 60px;
  height: auto;
  font-weight: bold;
  font-size: 16px;
}

input[type='checkbox'] {
  height: 30px;
  width: 30px;
}

.rpt-checkbox {
  font-weight: bolder;
  margin: 30px 15px 10px 0px;
}

.rpt-label {
  font-weight: bolder;
  margin-top: 30px;
}

.rpt-checkbox + div {
  display: inline-block;
}

div .report-download-btn {
  margin: 30pt 0px 10px 0px;
}
</style>
